<template>
  <main class="kb-main" id="kb-myclass-notice">
    <LearnSurvey
        v-if="windowActive"
        v-model:active="windowActive"
        :item="targetItem"
        :svy="targetAdded"
        @renewEnd="getLearnObjectResult"
    />
  </main>
</template>

<script>

import {computed, onMounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import LearnSurvey from '@/components/learn/window/LearnSurvey';
import {getFunc} from '@/assets/js/ui.init';
import {ACT_GET_CRSE_DIST_APPLY} from '@/store/modules/course/course';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {ACT_GET_LEARN_OBJECT_RESULT_LIST} from '@/store/modules/learn/learn';
import {getItem, lengthCheck, isSuccess} from '@/assets/js/util';
import {isSurvey} from '@/assets/js/modules/learn/learn-common';
import {ACT_GET_LEARN_OBJECT_ADDED, ACT_INSERT_LEARN_RESULT} from '@/store/modules/learn/learn';

export default {
  name: 'SurveyGeneral',
  components: {
    LearnSurvey,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage} = useAlert();
    const distCrseSn = computed(() => route.params.distCrseSn);
    const targetItem = ref({});
    const targetAdded = ref({});
    const windowActive = ref(false);
    const isLoading = ref(false);
    const params = reactive({
      distCrseSn: 0,
      lrnTrgtGrpDtlSn: 0,
    });

    const initLearnMetaList = () => {
      // console.log(distCrseSn.value);
      getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, () => {
        if (params.lrnTrgtGrpDtlSn > 0) {
          // console.log(params.lrnTrgtGrpDtlSn);
          store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_RESULT_LIST}`, distCrseSn.value).then(response => {
            if (lengthCheck(response)) {
              // console.log(getItem(response));
              const lrnObj = getItem(response);
              if (lrnObj.fnshYn === 'Y') {
                openResultLearnWindow(lrnObj);
              } else {
                openLearn(lrnObj);
              }
            }
          });
        } else {
          showMessage({
            title: '대상자아님',
            text: '해당연수의 대상자가 아닙니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        }
      });
    };

    const getDistObjParams = (target) => {
      const params = {lrnObjDtlDistSn: target.lrnObjDtlDistSn};
      if(isSurvey(target.lrnObjTyCd)){
        params.distObjSn = target.distSvySn;
        params.distObjNm = target.svyNm;
      }
      return params;
    }

    const openLearn = (target) => {
      if(isLoading.value) return;
      isLoading.value = true;
      if (target && target.lrnObjDtlDistSn > 0) {
        if(target.lrnRsltSn > 0){
          targetItem.value = target;
          getObjectAdded();
        }else{
          store.dispatch(`learn/${ACT_INSERT_LEARN_RESULT}`, getDistObjParams(target)).then(res => {
            if(isSuccess(res) && res.lrnRsltSn > 0){
              target.lrnRsltSn = res.lrnRsltSn;
              targetItem.value = target;
              getObjectAdded();
            } else {
              isLoading.value = false;
            }
          }).catch(e => {
            console.error(e);
            isLoading.value = false;
          });
        }
      } else{
        isLoading.value = false;
      }
      // console.log('2',target);
    };

    const openResultLearnWindow = (target) => {
      if (isSurvey(target.lrnObjTyCd)) {
        showMessage({
          title: '참여완료',
          text: '이미 설문에 참여하셨습니다.',
          callback: () => {
            router.push({name: 'Main'});
          }
        });
      }
    };

    const getObjectAdded = () => {
      store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_ADDED}`, targetItem.value.lrnObjDtlDistSn).then(res => {
        if(lengthCheck(res)){
          // 학습 결과도 같이 넣어준다.
          const added = getItem(res);
          added.lrnRsltSn = targetItem.value.lrnRsltSn;
          targetAdded.value = added;
          windowActive.value = true;

        } else {
          showMessage('학습자료가 없습니다.')
        }
        isLoading.value = false;
      }).catch(e => {
        console.error(e);
        isLoading.value = false;
      })
    }

    const getLearnObjectResult = () => {
      console.log('getLearnObjectResult');
    };

    onMounted(() => {
      initLearnMetaList();
    });

    return {
      windowActive,
      targetItem,
      targetAdded,
      getLearnObjectResult,
    };
  },
};
</script>


